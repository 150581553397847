import React from 'react';
import Link from '../Link';
import { LocalizationContext } from '../../hoc/Localization';
import { RouteContext } from '../../hoc/Route';
import styles from './styles.less';

const Locale = () => {
  const { locales, locale } = React.useContext(LocalizationContext);
  const { page, params, queryParams } = React.useContext(RouteContext);

  return (
    <div className={styles.locales}>
      {locales.map(value => (
        <Link
          {...params}
          queryParams={queryParams}
          route={page || 'index'}
          locale={value}
          key={value}
          className={[styles.locale, value === locale ? styles.active : {}].join(' ')}
        >
          {value}
        </Link>
      ))}
    </div>
  );
};

export default Locale;
