import React from 'react';
import { Layout, Row, Col } from 'antd';
import { Link as ScrollLink, scroller } from 'react-scroll';
import { LocalizationContext } from '../../../hoc/Localization';
import PaymentLogo from '../../TinyComponents/PaymantLogo';
import { RouteContext } from '../../../hoc/Route';
import { LocationContext } from '../../../hoc/Location';
import Locale from '../../Locale';
import Link from '../../Link';
import styles from './styles.less';
import { SettingsContext } from '../../../hoc/Settings';

const { Footer } = Layout;

const CustomFooter = () => {
  const {
    page,
    params: { locale: pageLocale },
  } = React.useContext(RouteContext);
  const { t, locale } = React.useContext(LocalizationContext);
  const {
    domainSettings: {
      facebook: { pageLink },
      domain,
      featureCommunity,
      showBlog,
      paymentMethod,
    },
  } = React.useContext(SettingsContext);
  function scrollTo() {
    setTimeout(() => {
      scroller.scrollTo('teachers', {
        duration: 500,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    }, 500);
  }
  const { locations } = React.useContext(LocationContext);
  const { country } = locations.find(l => l.default) || {};
  const lists = [
    <div className={styles.list}>
      <p className="title">{t('footer.company')}</p>
      <ul>
        <li>
          <Link route="about">{t('menu.about')}</Link>
        </li>
        {/* <li>
          <Link route="features">{t('footer.features')}</Link>
        </li>
        <li>
          <Link route="privacy">{t('footer.privacy')}</Link>
        </li>
        <li>
          <Link route="terms">{t('menu.terms')}</Link>
        </li> */}
        <li>
          <Link route="careers">{t('footer.careers')}</Link>
        </li>
        <li>
          <Link route="blogLanding" locale={pageLocale} url="privacy-policy">
            {t('footer.privacy')}
          </Link>
        </li>
        {/* <li>
          <Link route="contactUs">{t('footer.contact')}</Link>
        </li> */}
      </ul>
    </div>,

    <div className={styles.list}>
      <p className="title">{t('footer.schools')}</p>
      <ul>
        <li>
          <Link route="createCourse">{t('footer.register.for.free')}</Link>
        </li>
        <li>
          <a href="https://app.tutor-platform.com/login" target="_blank" rel="noopener noreferrer">
            {t('footer.lms')}
          </a>
        </li>
        {showBlog && (
          <li>
            {page === 'blogCategory' ? (
              <ScrollLink activeClass="active" to="teachers" smooth duration={500}>
                {t('footer.blog')}
              </ScrollLink>
            ) : (
              <a href={`https://${domain}/${locale}/blog/other`} onClick={() => scrollTo()}>
                {t('footer.blog')}
              </a>
            )}
          </li>
        )}
        {/* <li>
          <Link route="terms">{t('menu.terms')}</Link>
        </li> */}
        <li>
          <a href={`http://${domain}/${locale}/intro/?pricing=${true}`}>{t('footer.pricing')}</a>
        </li>
      </ul>
    </div>,

    <div className={styles.list}>
      <p className="title">{t('footer.students')}</p>
      <ul>
        {/* <li>
          <Link route="search">{t('footer.courses')}</Link>
        </li> */}
        {/* <li>
          <Link route="ourCommunities">{t('footer.communities.our')}</Link>
        </li> */}
        {showBlog && (
          <li>
            <Link route="blog">{t('footer.blog')}</Link>
          </li>
        )}
        <li>
          <a href="https://m.me/language.assessment.bot" target="_blank" rel="noopener noreferrer">
            {t('footer.messenger.test')}
          </a>
        </li>
        {featureCommunity && (
          <li>
            <a href="https://course.am/community" rel="noopener noreferrer">
              {t('footer.forum')}
            </a>
          </li>
        )}
      </ul>
    </div>,
  ];

  const follow = (
    <div className={styles.links}>
      <p className={[styles.follow, 'title'].join(' ')}>{t('footer.follow')}</p>
      <div id="socialLinks">
        <a id="facebookPage" href={pageLink} target="_blank" rel="noopener noreferrer">
          <img src="/static/images/footer/fb.svg" alt="facebook" />
        </a>
        <a
          id="linkedinPage"
          href="https://www.linkedin.com/company/tutor-platform/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/static/images/footer/linkedin.svg" alt="linkedin" />
        </a>
        <a
          id="twitterPage"
          href="https://twitter.com/tutorbotio"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/static/images/footer/twitter.svg" alt="twitter" />
        </a>
      </div>
    </div>
  );

  const download = (
    <div className={styles.links}>
      <p>
        <img src="/static/images/footer/tutorbot.svg" alt="tutorbot" />
      </p>
      <p className="title">{t('footer.download')}</p>
      <div className={styles.downloads}>
        <a
          href="https://itunes.apple.com/us/app/tutor-bot/id1250211933?ls=1&mt=8"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/static/images/footer/app_store.svg" alt="apple-store" />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=io.tutorbot&hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/static/images/footer/google.svg" alt="play-store" />
        </a>
      </div>
      {paymentMethod && (
        <div className={styles.paymentLogoFooter}>
          <PaymentLogo paymentMethod={paymentMethod} footer />
        </div>
      )}
    </div>
  );

  const domainsList = {
    Armenia: 'https://www.course.am/',
    Germany: 'https://www.course.de/',
    Russia: 'https://www.course.ru/',
  };
  Object.keys(domainsList).forEach(key => {
    if (country === key) {
      delete domainsList[country];
    }
  });
  const showDomainsList = Object.keys(domainsList).map(key => (
    <a href={`${domainsList[key]}`} key={key} target="_blank" rel="noopener noreferrer">
      {`| ${key}`}
    </a>
  ));

  const selector = (
    <div className={styles.location}>
      <img
        style={{ marginBottom: '5px' }}
        src="/static/images/footer/location.svg"
        alt="location"
      />
      <a href={`https://${domain}/`}>{country}</a>
      {showDomainsList}
    </div>
  );
  const copyright = (
    <a href="https://www.tutor-platform.com/">
      {t('powered.by.tutor.platform')}
      <img
        style={{ margin: '0px 3px 3px 3px' }}
        src="/static/images/footer/copyright.svg"
        alt="copyright"
      />
      {new Date().getFullYear()}
    </a>
  );

  return (
    <Footer hidden={page === 'createCourseSchool'} className={styles.footer}>
      {!['search'].includes(page) && (
        <div className={styles.top}>
          <div className="container">
            <Row type="flex" justify="space-between" gutter={20}>
              <Col sm={8} md={12} xl={4}>
                {lists[0]}
              </Col>
              <Col sm={8} md={12} xl={4}>
                {lists[1]}
              </Col>
              <Col sm={8} md={12} xl={4}>
                {lists[2]}
              </Col>
              <Col sm={8} md={12} xl={4}>
                {follow}
              </Col>
              <Col sm={16} md={24} xl={8}>
                {download}
              </Col>
            </Row>
          </div>
        </div>
      )}

      <div className={styles.bottom}>
        <div className="container">
          <Row type="flex" justify="space-between" gutter={20}>
            <Col xs={24} md={8}>
              {selector}
            </Col>
            <Col xs={24} md={8}>
              {copyright}
            </Col>
            <Col xs={24} md={8}>
              <Locale />
            </Col>
          </Row>
        </div>
      </div>
    </Footer>
  );
};

export default CustomFooter;
